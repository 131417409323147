
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { customerTagList, removeCustomerTag, saveCustomerTag } from "@/api/request/customer";

//组件
@Component({
  name: "CustomerTag",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,

    //请求数据
    name: "", //名称
  };

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await customerTagList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private handleAdd(): void {
    //数据赋值
    this.addForm = {
      //请求数据
      id: 0, //客服标签ID
      name: "", //客服标签名称
    };

    //显示界面
    this.dialogVisible = true;
    this.dialogStatus = "create";
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.addForm = {
      //请求数据
      id: row.id, //客服标签ID
      name: row.name, //客服标签名称
    };

    //显示界面
    this.dialogVisible = true;
    this.dialogStatus = "update";
  }

  //处理删除
  private handleDelete(row: any): void {
    //删除消息
    this.$confirm("确定删除客服标签 ?", "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //显示等待
        this.listLoading = true;

        //删除博主
        await removeCustomerTag({ id: row.id });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //---------------------------- 编辑/创建客服 ----------------------------
  //定义变量
  private dialogStatus: string = ""; //对话框状态
  private dialogVisible: boolean = false; //是否显示
  private dialogLoading: boolean = false; //对话框等待
  private dialogTitleMap: any = { update: "编辑客服标签", create: "新增客服标签" }; //对话框标题

  //表单数据
  private addForm: any = {
    //请求数据
    id: 0, //客服标签ID
    name: "", //客服标签名称
  };

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //处理创建
  private async createData() {
    //异常处理
    if (this.addForm.name == "") return Message({ type: "error", duration: 5 * 1000, message: "请输入客服标签名称" });

    //保存数据
    await saveCustomerTag(this.addForm);

    //显示提示
    this.$notify({ title: "成功", message: "创建成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogVisible = false;

    //重新获取数据
    this.getList();
  }

  //处理更新
  private async updateData() {
    //异常处理
    if (this.addForm.name == "") return Message({ type: "error", duration: 5 * 1000, message: "请输入客服标签名称" });

    //保存数据
    await saveCustomerTag(this.addForm);

    //显示提示
    this.$notify({ title: "成功", message: "更新成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogVisible = false;

    //重新获取数据
    this.getList();
  }
}
